import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Mifflin from './Calculators/Mifflin';
import Home from './Home';
import PennState from './Calculators/PennState';

import Navigation from './Navigation';

import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  const [view, setView] = useState("Home");

  const renderView = () => {
    switch (view) {
      case "Home":
        return <Home />;
      case "Mifflin":
          return <Mifflin />;
      case "PennSt":
            return <PennState />;
      default:
        return <Home />;
    }
  }

  return (
    <React.Fragment>
      <Navigation
        setView={setView}
        view={view}
        />
      <Container id="main" component="main" maxWidth="sm">
        {renderView()}
      </Container>
    </React.Fragment>
  );
}

export default App;
