import * as React from 'react';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

export default function Ventilation(props) {
    const [enteredValue, setEnteredValue] = useState(undefined);

    const onValueChange = (value) => {
      setEnteredValue(value);
      props.setValue(Number(value));
    }
  
    return (
  <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                  <TextField
                      fullWidth
                      required={true}
                      label={"Ventilation (L/min)"}
                      type='number'
                      id={"Ventilation"}
                      name={"Ventilation"}
                      value={enteredValue}
                      onChange={(e) => onValueChange(e.target.value)}
                  />
              </Grid>
              </Grid>
    );
}
