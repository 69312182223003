import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Divider } from '@mui/material';
import { CalculateBmiCategory, Print } from "../Utility";

export default function BasicTable(props) {
    const genders = ["Male", "Female"]
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <strong>Height</strong>
                        </TableCell>
                        <TableCell align="right">{Print(props.height[0])}cm</TableCell>
                        <TableCell align="right">{Print(props.height[1])}in</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <strong>Weight</strong>
                        </TableCell>
                        <TableCell align="right">{Print(props.weight[0])}kg</TableCell>
                        <TableCell align="right">{Print(props.weight[1])}lbs</TableCell>
                    </TableRow>
                    {props.usualWeight &&
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">

                                <strong>Usual Weight</strong>
                            </TableCell>
                            <TableCell align="right">{Print(props.usualWeight[0])}kg</TableCell>
                            <TableCell align="right">{Print(props.usualWeight[1])}lbs</TableCell>
                        </TableRow>
                    }
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">

                            <strong>Age/Gender</strong>
                        </TableCell>
                        <TableCell align="right">{props.age}</TableCell>
                        <TableCell align="right">{genders[props.gender]}</TableCell>
                    </TableRow>
                    {props.temperature &&
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">

                                <strong>Temperature</strong>
                            </TableCell>
                            <TableCell align="right">{props.temperature[0]}C</TableCell>
                            <TableCell align="right">{props.temperature[1]}F</TableCell>
                        </TableRow>}
                    {props.ventilationRate &&
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">

                                <strong>Ventilation</strong>
                            </TableCell>
                            <TableCell align="right">{props.ventilationRate}L/min</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>}
                </TableBody>
            </Table>
            <Divider />
            <Divider />
            <Divider />
            <Divider />


            <Table aria-label="simple table">
                <TableBody>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <strong>Basal Metabolic Rate</strong>
                        </TableCell>
                        <TableCell align="right">{Print(props.basalMetabolicRate)} Calories</TableCell>
                        <TableCell align="right">{Print(props.basalMetabolicRate / props.weight[0])}cal/kg</TableCell>
                    </TableRow>
                    {props.restingMetabolicRate &&
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">

                                <strong>Resting Metabolic Rate</strong>
                            </TableCell>
                            <TableCell align="right">{Print(props.restingMetabolicRate)} Calories</TableCell>
                            <TableCell align="right">{Print(props.restingMetabolicRate / props.weight[0])}cal/kg</TableCell>
                        </TableRow>
                    }
                    {props.factor && props.factor !== 1 &&
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">

                                <strong>+ Factor ({props.factor})</strong>
                            </TableCell>
                            <TableCell align="right">{Print(props.basalMetabolicRate * props.factor)} Calories</TableCell>
                            <TableCell align="right">{Print((props.basalMetabolicRate * props.factor) / props.weight[0])}cal/kg</TableCell>
                        </TableRow>
                    }
                    {props.fluidRequirements &&
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <strong>Fluid</strong>
                        </TableCell>
                        <TableCell align="right">{Print(props.fluidRequirements[0])}ml</TableCell>
                        <TableCell align="right">{Print(props.fluidRequirements[1])}oz</TableCell>
                    </TableRow>
                    }
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <strong>Body Mass Index</strong>
                        </TableCell>
                        <TableCell align="right">{Print(props.bodyMassIndex)}</TableCell>
                        <TableCell align="right">{CalculateBmiCategory(props.bodyMassIndex)}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <strong>Ideal Weight</strong>
                        </TableCell>
                        <TableCell align="right">{Print(props.idealBodyWeight[0])}kg</TableCell>
                        <TableCell align="right">{Print(props.idealBodyWeight[1])}lbs</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <strong>% Weight</strong>
                        </TableCell>
                        <TableCell align="right">{Print((props.weight[0] / props.idealBodyWeight[0]) * 100)} %IBW</TableCell>
                        {props.usualWeight &&
                            <TableCell align="right">{Print((props.weight[0] / props.usualWeight[0]) * 100)} %UBW</TableCell>
                        }
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
