export function Print(num) {
    return Math.round(num * 100) / 100;
}

export function CalculateBodyMassIndex(heightInCm, weightInKg) {
    let height = heightInCm / 100;
    return Print(weightInKg / (height * height));
}

export function CalculateFluidRequirements(age, weightInKg, bodyMassIndex, idealBodyWeightInKg) {
    let mlPerKg = 30;
    if (age >= 65) {
        mlPerKg = 25;
    }
    let weight = weightInKg;
    if (bodyMassIndex > 29.9) {
        weight = idealBodyWeightInKg;
    }
    let needs = mlPerKg * weight;
    return [Print(needs), Print(MillilitersToOunces(needs))];
}

export function CalculateIdealBodyWeight(heightInches, gender) {
    if (heightInches >= 60) {
        let inchesOverSixty = heightInches - 60;
        if (gender === 0) {
            return Print(106 + (6 * inchesOverSixty));
        }
        else {
            return Print(100 + (5 * inchesOverSixty));
        }
    }
    else {
        let inchesUnderSixty = 60 - heightInches;
        if (gender === 0) {
            return Print(106 - (3 * inchesUnderSixty));
        }
        else {
            return Print(100 - (2.5 * inchesUnderSixty));
        }
    }
}

export function CalculateBasalMetabolicRate(heightCm, weightKg, age, gender){
    if(gender === 0) {
        return Print((9.99 * weightKg) + (6.25 * heightCm) - (4.92 * age) + 5.0);
    }
    else {
        return Print((9.99 * weightKg) + (6.25 * heightCm) - (4.92 * age) - 161)
    }
    
}

export function CalculatePennStateNeeds(bmr, bmi, age, ventilation, temperatureInC){
    if(bmi < 30 || (age < 60 && bmi >= 30)){ 
        return bmr * 0.96 + ventilation * 31 + temperatureInC * 167 - 6212;
    }
    if(bmi >= 30 && age >= 60) {
        return bmr * 0.71 + ventilation + 64 + temperatureInC * 85 - 3085;
    }
}

export function CalculateModifiedPennStateNeeds(bmr, temperatureInC) {
    return bmr * 0.94 + temperatureInC * 186 - 6597;
}

export function CalculateBmiCategory(bmi){
    let category = "";
    if (bmi < 18.50)
        category = "Underweight";
    if (bmi >= 18.5 && bmi <= 24.99)
        category = "Normal";
    if (bmi > 24.99 && bmi <= 29.99)
        category = "Overweight";
    if (bmi > 29.99 && bmi <= 34.99)
        category = "Obese I";
    if (bmi > 34.99 && bmi <= 39.99)
        category = "Obese II";
    if (bmi > 39.99)
        category = "Obese III" ;
    return category;
}

export function MillilitersToOunces(ml){
    return ml / 29.5735296;
}

export function PoundsToKilograms(lbs){
    return lbs / 2.2;
}

export function KilogramstoPounds(kg){
    return kg * 2.2;
}