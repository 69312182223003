import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Weight from '../Modules/Weight';
import UsualWeight from '../Modules/UsualWeight';
import Height from '../Modules/Height';
import AgeGender from '../Modules/AgeGender';
import ActivityFactor from '../Modules/ActivityFactor';
import Results from './Results';
import { Button } from '@mui/material';
import {
  CalculateBasalMetabolicRate,
  CalculateBodyMassIndex,
  CalculateFluidRequirements,
  CalculateIdealBodyWeight,
  PoundsToKilograms
} from '../Utility';

export default function Mifflin(props) {
  const [height, setHeight] = useState(undefined);
  const [weight, setWeight] = useState(undefined);
  const [age, setAge] = useState(undefined);
  const [gender, setGender] = useState(0);
  const [factor, setFactor] = useState(1.0);
  const [usualWeight, setUsualWeight] = useState(undefined);

  const [basalMetabolicRate, setBasalMetabolicRate] = useState(undefined);
  const [fluidRequirements, setFluidRequirements] = useState(undefined);
  const [bodyMassIndex, setBodyMassIndex] = useState(undefined);
  const [idealBodyWeight, setIdealBodyWeight] = useState(undefined);

  const [view, setView] = useState(0);


  const Calculate = () => {
    setBasalMetabolicRate(CalculateBasalMetabolicRate(height[0], weight[0], age, gender));
    let bmi = CalculateBodyMassIndex(height[0], weight[0]);
    setBodyMassIndex(bmi);
    var ibw_lbs = CalculateIdealBodyWeight(height[1], gender);
    let ibw_kg = PoundsToKilograms(ibw_lbs);
    setIdealBodyWeight([ibw_kg, ibw_lbs]);
    setFluidRequirements(CalculateFluidRequirements(age, weight[0], bmi, ibw_kg));
    setView(1);
  }

  const disableClick = () => {
    if (height && height[0] && weight && weight[0] && age && factor) return false;
    return true;
  }

  return (
    <div>
      {view === 0 &&
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Height
              setValue={setHeight}
            />
          </Grid>
          <Grid item xs={12}>
            <Weight
              setValue={setWeight}
            />
          </Grid>
          <Grid item xs={12} >
            <AgeGender
              setAge={setAge}
              setGender={setGender}
            />
          </Grid>
          <Grid item xs={12} >
            <ActivityFactor setValue={setFactor} />
          </Grid>
          <Grid item xs={12} >
            < UsualWeight
              setValue={setUsualWeight}
            />
          </Grid>
          <Grid item xs={12} >
            <Button fullWidth variant="contained" onClick={() => Calculate()} disabled={disableClick()}>Calculate</Button>
          </Grid>
        </Grid>
      }
      {view === 1 &&
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Results
              height={height}
              weight={weight}
              age={age}
              factor={factor}
              gender={gender}
              usualWeight={usualWeight}
              basalMetabolicRate={basalMetabolicRate}
              fluidRequirements={fluidRequirements}
              bodyMassIndex={bodyMassIndex}
              idealBodyWeight={idealBodyWeight}
            />
          </Grid>
          <Grid item xs={12} >
            <Button fullWidth variant="contained" onClick={() => setView(0)}>Back</Button>
          </Grid>
        </Grid>
      }
    </div>


  );
}
