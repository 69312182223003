
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Print } from './Utility';
const DataInput = (props) => {
    const [selectedRadio, setSelectedRadio] = useState(0);

    useEffect(() => {
        if (props.enteredValue && props.convert) {
            props.convert(selectedRadio);
        }
    }, [props.enteredValue, selectedRadio, props.convert, props]);

    const GetRadioLabel = (index) => {
        if(!props.enteredValue || !props.convertedValue) {
            return props.units[index];
        }
        if(props.enteredValue && props.convertedValue) {
            return `${props.units[index]} ${(selectedRadio === index ? 
                ` (${Print(props.enteredValue)})` : ` (${Print(props.convertedValue)})`)}`;
        }
    }

    const OnRadioSelect = (index) => {
        // if(index !== selectedRadio) {
        //     var newValue = convertedValue;
        //     debugger;
        //     setConvertedValue(enteredValue);
        //     setEnteredValue(newValue);
        // }
        setSelectedRadio(index);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    required={props.required}
                    label={props.label}
                    type='number'
                    id={props.label}
                    name={props.label}
                    value={props.enteredValue}
                    onChange={(e) => props.setEnteredValue(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectedRadio}
                        onChange={(e) => OnRadioSelect(Number(e.target.value))}
                    >
                        <FormControlLabel value={0} control={<Radio />} label={GetRadioLabel(0)} />
                        <FormControlLabel value={1} control={<Radio />} label={GetRadioLabel(1)} />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>

    );
}

export default DataInput;
