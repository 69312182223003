import * as React from 'react';
import UpdateCard from './UpdateCard';
import logo1 from './logo1.png';
export default function Home(props) {
  return (
  <div>
    <img src={logo1} alt="banner" id="banner" />
    <UpdateCard 
        title={"Welcome!"}
        date={"7-7-2024"}
        text={"Welcome to the NutritionBud webapp refresh. More updates coming soon."}
    />
  </div>
  );
}
