import * as React from 'react';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

export default function ActivityFactor(props) {
  const [enteredValue, setEnteredValue] = useState(1.0);

  const onFactorChange = (value) => {
    setEnteredValue(value);
    props.setValue(Number(value));
  }

  return (
<Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    required={true}
                    label={"Factor"}
                    type='number'
                    id={"factor"}
                    name={"Factor"}
                    value={enteredValue}
                    onChange={(e) => onFactorChange(e.target.value)}
                />
            </Grid>
            </Grid>
  );
}
