import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Weight from '../Modules/Weight';
import Ventilation from '../Modules/Ventilation';
import Height from '../Modules/Height';
import AgeGender from '../Modules/AgeGender';
import Temperature from '../Modules/Temperature';
import Results from './Results';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Checkbox } from '@mui/material';
import {
  CalculateBasalMetabolicRate,
  CalculateBodyMassIndex,
  CalculateIdealBodyWeight,
  CalculatePennStateNeeds,
  CalculateModifiedPennStateNeeds,
  PoundsToKilograms
} from '../Utility';

export default function PennState() {
  const [height, setHeight] = useState(undefined);
  const [weight, setWeight] = useState(undefined);
  const [age, setAge] = useState(undefined);
  const [gender, setGender] = useState(0);
  const [temperature, setTemperature] = useState(undefined);
  const [ventilationRate, setVentilationRate] = useState(undefined);
  const [hasVentilator, setHasVentilator] = useState(true);

  const [basalMetabolicRate, setBasalMetabolicRate] = useState(undefined);
  const [bodyMassIndex, setBodyMassIndex] = useState(undefined);
  const [idealBodyWeight, setIdealBodyWeight] = useState(undefined);
  const [restingMetabolicRate, setRestingMetabolicRate] = useState(undefined);

  const [view, setView] = useState(0);

  const Calculate = () => {
    let bmr = CalculateBasalMetabolicRate(height[0], weight[0], age, gender);
    setBasalMetabolicRate(bmr);
    let bmi = CalculateBodyMassIndex(height[0], weight[0]);
    setBodyMassIndex(bmi);
    var ibw_lbs = CalculateIdealBodyWeight(height[1], gender);
    let ibw_kg = PoundsToKilograms(ibw_lbs);
    setIdealBodyWeight([ibw_kg, ibw_lbs]);
    if (hasVentilator) {
      setRestingMetabolicRate(CalculatePennStateNeeds(bmr, bmi, age, ventilationRate, temperature[0]));
    }
    else {
      setRestingMetabolicRate(CalculateModifiedPennStateNeeds(bmr, temperature[0]));
    }

    setView(1);
  }

  const disableClick = () => {
    if (height && height[0] && weight && weight[0] && age && temperature && (!hasVentilator || (ventilationRate && hasVentilator))) return false;
    return true;
  }

  return (
    <div>
      {view === 0 &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Height
              setValue={setHeight}
            />
          </Grid>
          <Grid item xs={12}>
            <Weight
              setValue={setWeight}
            />
          </Grid>
          <Grid item xs={12} >
            <AgeGender
              setAge={setAge}
              setGender={setGender}
            />
          </Grid>
          <Grid item xs={12} >
            <Temperature setValue={setTemperature} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={<Checkbox
              checked={hasVentilator}
              onChange={() => setHasVentilator(!hasVentilator)}
            />} label="Patient is Vented" />

          </Grid>
          {hasVentilator &&
            <Grid item xs={12} >
              <Ventilation setValue={setVentilationRate} />
            </Grid>
          }
          <Grid item xs={12} >
            <Button fullWidth variant="contained" onClick={() => Calculate()} disabled={disableClick()}>Calculate</Button>
          </Grid>
        </Grid>
      }
      {view === 1 &&
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Results
              height={height}
              weight={weight}
              age={age}
              gender={gender}
              basalMetabolicRate={basalMetabolicRate}
              bodyMassIndex={bodyMassIndex}
              idealBodyWeight={idealBodyWeight}
              ventilationRate={hasVentilator ? ventilationRate : undefined}
              temperature={temperature}
              restingMetabolicRate={restingMetabolicRate}
            />
          </Grid>
          <Grid item xs={12} >
            <Button fullWidth variant="contained" onClick={() => setView(0)}>Back</Button>
          </Grid>
        </Grid>
      }
    </div>
  );
}
