import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import MasksIcon from '@mui/icons-material/Masks';

function Navigation(props) {
  const [drawer, toggleDrawer] = useState(false);

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={() => toggleDrawer(false)}>
      <List>
        {['Home', 'Mifflin', 'PennSt'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => props.setView(text)}>
              <ListItemIcon>
                {text === "Home" && <HomeIcon />}
                {text === "Mifflin" && <DirectionsRunIcon />}
                {text === "PennSt" && <MasksIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );



  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {props.view}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer open={drawer} onClose={() => toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </React.Fragment>
  );
}

export default Navigation;
