import * as React from 'react';
import DataInput from '../DataInput';
import { useState } from 'react';

export default function AgeGender(props) {
  const [enteredValue, setEnteredValue] = useState(undefined);

  const onAgeChange = (value) => {
    setEnteredValue(value);
    props.setAge(Number(value));
  }
  const onGenderChange = (value) => {
    props.setGender(Number(value));
  }

  return (
      <DataInput
          required={true}
          convert={onGenderChange}
          enteredValue={enteredValue}
          setEnteredValue={onAgeChange}
          units={["Male", "Female"]}
          label={"Age"} />
  );
}
