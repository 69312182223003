import * as React from 'react';
import DataInput from '../DataInput';
import { useState } from 'react';

export default function Weight(props) {
  const [convertedValue, setConvertedValue] = useState(undefined);
  const conversionFactor = 2.54;
  const [enteredValue, setEnteredValue] = useState(undefined);

  const Convert = (selectedUnit) => {
    if (selectedUnit === 0) {
        setConvertedValue(enteredValue / conversionFactor);
        props.setValue([Number(enteredValue), Number(convertedValue)]);
    }
    else {
        setConvertedValue(enteredValue * conversionFactor);
        props.setValue([Number(convertedValue), Number(enteredValue)]);
    }
};

  return (
      <DataInput
          required={true}
          convert={Convert}
          convertedValue={convertedValue}
          enteredValue={enteredValue}
          setEnteredValue={setEnteredValue}
          units={["cm", "in"]}
          label={"Height"} />
  );
}
