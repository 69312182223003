import * as React from 'react';
import DataInput from '../DataInput';
import { useState } from 'react';

export default function Temperature(props) {
  const [convertedValue, setConvertedValue] = useState(undefined);
  const [enteredValue, setEnteredValue] = useState(undefined);

  const Convert = (selectedUnit) => {
    if (selectedUnit === 0) {
        setConvertedValue((enteredValue * (9/5)) + 32);
        props.setValue([Number(enteredValue),Number(convertedValue)]);
    }
    else {
        setConvertedValue((enteredValue - 32) * (5/9));
        props.setValue([Number(convertedValue), Number(enteredValue)]);
    }
};

  return (
      <DataInput
          required={true}
          convert={Convert}
          convertedValue={convertedValue}
          enteredValue={enteredValue}
          setEnteredValue={setEnteredValue}
          units={["C", "F"]}
          label={"Temperature"} />
  );
}
